import React from "react";
import { useParams } from "react-router";
import { PATH_USER } from "src/routes/paths";
import i18n from "src/locales/i18n";

const useVaultTab = () => {
  const { id } = useParams();

  // i18n.t("adminCommunication.faqs.faqq"),

  const TabsList = [
    {
      name: "overview",
      href: PATH_USER.crowdProduct.view(id),
      value: i18n.t("latest.overview"),
    },
    {
      name: "participate",
      href: "participate",
      value: i18n.t("latest.participate"),
    },
    {
      name: "updates",
      href: "updates",
      value: i18n.t("latest.updates"),
    },
    {
      name: "t&c",
      href: "t&c",
      value: i18n.t("latest.tc"),
    },
  ];
  return TabsList;
};

export default useVaultTab;
