import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";
import * as Yup from "yup";

const schema = Yup.object().shape({
  code: Yup.string().required("OTP is required"),
});

const defaultValues = {
  email: "",
  password: "",
  otp: "",
};

const useLoginWithEmailVerify = (otpOpen, data) => {
  const { password, code, email } = data;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { setValue } = methods;

  useEffect(() => {
    if (otpOpen) {
      setValue("email", email);
      setValue("password", password);
    }
  }, [otpOpen,code, password, email]);
  const onSubmit = async (inputData) => {
    const { email, password, code } = inputData;
    const reqData = new FormData();
    reqData.append("email", email);
    reqData.append("password", password);
    reqData.append("otp", code);

    try {
      const { data, status } = await axiosInstance.post(
        "/api/emailverify",
        reqData
      );
      if (status === 200) {
        localStorage.setItem("isAdmin", Boolean(data.user.is_super_admin));
        setSession(data.access_token);
        navigate(0);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return { methods, onSubmit: methods.handleSubmit(onSubmit) };
};

export default useLoginWithEmailVerify;
