import { lazy } from "react";
import Loadable from "src/routes/Loadable";


const Promotion = Loadable(
  lazy(() => import("src/pages/admin/promotion/index"))
);

const AddPromotion = Loadable(
  lazy(() => import("src/pages/admin/promotion/components/AddPromotion"))
);
const EditPromotion = Loadable(
  lazy(() => import("src/pages/admin/promotion/components/EditPromotion"))
);

const promotion = [
  {
    path: "promotions",
    children: [
      { index: true, element: <Promotion /> },
      { path: "add", element: <AddPromotion /> },
        { path: ":id", element: <EditPromotion /> },
    ],
  },
];

export default promotion;
