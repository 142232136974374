import { lazy } from "react";
import { Navigate } from "react-router";
import AuthGuard, { UserGuard } from "src/guards/AuthGuard";
import Layout from "src/layouts/layout";
import Loadable from "../Loadable";
import businessBuilder from "./businessBuilder";
import financial from "./financial";
import genealogy from "./genealogy";
import helpCenter from "./helpCenter";
import profile from "./profile";
import subscriptions from "./subscriptions";
import ViewVault from "src/pages/user/crowdProduct/viewVault/viewVault";

const ProductList = Loadable(
  lazy(() =>
    import("src/pages/user/onlineStore/productSubscription/productList/index")
  )
);
const ProductDetails = Loadable(
  lazy(() =>
    import("src/pages/user/onlineStore/productSubscription/details/index")
  )
);
const Checkout = Loadable(
  lazy(() =>
    import("src/pages/user/onlineStore/productSubscription/checkout/index")
  )
);
const MyOrders = Loadable(
  lazy(() => import("src/pages/user/onlineStore/myOrders/index"))
);

const ViewOrderById = Loadable(
  lazy(() => import("src/pages/invoice/myOrders/index"))
);

const BlogPosts = Loadable(
  lazy(() => import("src/pages/user/blogs/BlogPosts"))
);
const BlogPost = Loadable(lazy(() => import("src/pages/user/blogs/BlogPost")));

const Dashboard = Loadable(
  lazy(() => import(`src/pages/user/dashboard/index`))
);

const IncomeReport = Loadable(
  lazy(() => import("src/pages/user/incomeReport"))
);
const MissedPoints = Loadable(
  lazy(() => import("src/pages/user/missedPoints"))
);

const Events = Loadable(lazy(() => import("src/pages/user/events/index")));
const Crowd = Loadable(lazy(() => import("src/pages/user/crowdProduct/index")));
const PurchaseHistory = Loadable(
  lazy(() => import("src/pages/user/crowdProduct/purchaseHistory/index"))
);
const OverView = Loadable(
  lazy(() => import("src/pages/user/crowdProduct/viewVault/overView"))
);
const Participate = Loadable(
  lazy(() => import("src/pages/user/crowdProduct/viewVault/Participate"))
);
const Updates = Loadable(
  lazy(() => import("src/pages/user/crowdProduct/viewVault/Updates"))
);
const TermsAndConditions = Loadable(
  lazy(() => import("src/pages/user/crowdProduct/viewVault/TermsAndConditions"))
);

const Promotions = Loadable(
  lazy(() => import("src/pages/user/promotions/index"))
);

const Promotion = Loadable(
  lazy(() => import("src/pages/user/promotions/components/Promotion"))
);
const Documents = Loadable(
  lazy(() => import("src/pages/user/helpCenter/DowloadDoc/Index.js"))
);
const Videos = Loadable(
  lazy(() => import("src/pages/user/helpCenter/video/index.js"))
);

const user = [
  {
    path: "user",
    element: (
      <AuthGuard>
        <UserGuard>
          <Layout />
        </UserGuard>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="dashboard" />, index: true },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "promotion",
        children: [
          { index: true, element: <Promotions /> },
          { path: ":id", element: <Promotion /> },
        ],
      },
      {
        path: "online-store",
        children: [
          {
            path: "product-subscription",
            children: [
              { index: true, element: <ProductList /> },
              {
                path: "checkout",
                element: <Checkout />,
              },
              { path: ":name", element: <ProductDetails /> },
            ],
          },
          {
            path: "my-orders",
            children: [
              { index: true, element: <MyOrders /> },
              {
                path: ":id",
                element: <ViewOrderById />,
              },
            ],
          },
        ],
      },
      {
        path: "blogs",
        children: [
          { index: true, element: <BlogPosts /> },
          { path: ":id", element: <BlogPost /> },
        ],
      },
      { path: "income-report", element: <IncomeReport /> },
      { path: "missed-points", element: <MissedPoints /> },
      {
        path: "crowdProduct",

        children: [
          { index: true, element: <Navigate to="vault" /> },
          { path: "vault", element: <Crowd /> },
          {
            path: ":id",
            element: <ViewVault />,
            children: [
              { index: true, element: <Navigate to="overview" /> },
              ,
              { path: "overview", element: <OverView /> },
              { path: "Participate", element: <Participate /> },
              { path: "Updates", element: <Updates /> },
              { path: "T&C", element: <TermsAndConditions /> },
            ],
          },
          { path: "purchase-history", element: <PurchaseHistory /> },
        ],
      },
      {
        path: "resource",
        children: [
          { path: "documents", element: <Documents /> },
          { path: "videos", element: <Videos /> },
        ],
      },

      { ...businessBuilder },
      { ...genealogy },
      { ...subscriptions },
      { ...financial },
      { ...helpCenter },
      { ...profile },
    ],
  },
];

export default user;
