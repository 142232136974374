import { Box, Grid } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useLocales from "src/hooks/useLocales";
import { PATH_USER } from "src/routes/paths";
import useFetchSummary from "../hooks/useFetchSummary";
import useFetchWitPagination from "../hooks/useFetchWithPagination";
import { DataList, FilterCard } from "./components";
import Summary from "./components/summary";
import { useForm } from "react-hook-form";
import FilterBar from "src/components/filterBar";

const Index = () => {
  const { translate } = useLocales();

  const filterMethods = useForm({
    defaultValues: {
      start_date: "",
      end_date: "",
      user_id: "",
      amount_type: "all",
    },
  });
  const filter = filterMethods.watch();
  const { data, fetchData, ...rest } = useFetchWitPagination(filter, "ewallet");
  const { summary } = useFetchSummary("ewallet-data");

  const onSubmit = filterMethods.handleSubmit((inputData) => {
    fetchData(1, inputData);
  });
  return (
    <div>
      <Page title={translate("userFinancial.eWallet.ewalletTitile")}>
        <Box sx={{ p: 2 }}>
          <HeaderBreadcrumbs
            heading={translate("userFinancial.eWallet.myEwallet")}
            links={[
              { name: translate("dashboard"), href: PATH_USER.root },
              { name: translate("userFinancial.eWallet.myEwallet") },
            ]}
          />

          <Grid container spacing={2}>
            {/* <Summary summary={summary} /> */}
            <Grid item xs={12} md={12}>
              <FilterBar methods={filterMethods} onSubmit={onSubmit}>
                <FilterCard methods={filterMethods} onSubmit={onSubmit} />
              </FilterBar>
            </Grid>
            <Grid item xs={12} md={12}>
              <DataList data={data} {...rest} />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </div>
  );
};

export default Index;
