import DatePicker from "@mui/lab/DatePicker";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import useLocales from "src/hooks/useLocales";

import UserAutoComplete from "src/components/UsersAutoComplete";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import AmountType from "./components/amountType";
import RowsPerPage from "./components/rowsPerPage";
import UsersSearch from "src/components/autoComplete/users";
import Users from "src/components/users";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";

const FilterCard = ({ methods, onSubmit }) => {
  const { translate } = useLocales();

  const { control, setValue } = methods;
  return (
    <div>
      <Box>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box
            sx={{
              display: "grid",
              rowGap: 3,
              columnGap: 2,
              marginTop: 3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(5, 1fr)",
              },
            }}
          >
            <RHFDatePicker
              label={translate("adminFinancial.payout.pickStartDate")}
              name="start_date"
              size="small"
            />
            <RHFDatePicker
              label={translate("adminFinancial.payout.pickEndDate")}
              name="end_date"
              size="small"
            />
            <UsersSearch name="user_id" props={{ size: "small" }} />
            <AmountType />
            {/* <RowsPerPage /> */}
            <Box sx={{ float: "right" }}>
              <Button type="submit" variant="contained" name="get-report">
                {translate("userFinancial.eWallet.getReport")}
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </div>
  );
};

export default FilterCard;
