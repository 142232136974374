import { styled } from "@mui/material/styles";
import Logo from "src/components/Logo";
import GetStartedTitle from "./GetStartedTitle";
import { Box } from "@mui/material";

const Wrapper = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  left:0,
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  borderBottom: "0",
  justifyContent: "space-between",

  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    // padding: theme.spacing(7, 5, 0, 7),
  },
}));

const Header = () => (
  <Wrapper>
    <Box
      sx={{
        width: { xs: "40%", sm: "20%" },
      }}

    >
      <Logo />
    </Box>

    {/* <GetStartedTitle /> */}
  </Wrapper>
);

export default Header;
