import {
  Box,
  Button,
  Card,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import useVaultTab from "./useVaultTab";
import { capitalCase } from "change-case";

const ViewVault = () => {
  const TabsList = useVaultTab();

  const [value, setValue] = useState("overview", "overview");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Card sx={{ p: 2, mb: 2 }}>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
            >
              {TabsList.map((tab) => {
                return (
                  <Tab
                    disableRipple
                    key={tab.name}
                    label={capitalCase(tab.value)}
                    value={tab.name}
                    name={tab.name}
                    onClick={() => navigate(tab.href)}
                  />
                );
              })}
            </Tabs>
          </Card>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default ViewVault;
