import { Badge, Box, Stack, Toolbar, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { Link } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Logo from "src/components/Logo";
import { IconButtonAnimate } from "src/components/animate";
import Settings from "src/components/settings";
import { HEADER } from "src/config";
import useAuth from "src/hooks/useAuth";
import useOffSetTop from "src/hooks/useOffSetTop";
import useResponsive from "src/hooks/useResponsive";
import { RootStyle } from "src/layouts/shared";
import { PATH_DASHBOARD } from "src/routes/paths";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import QuickPopover from "./QuickPopover";

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 8s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings((prev) => !prev);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };
  const { isAdmin } = useAuth();
  const classes = useStyles();
  return (
    <>
      <RootStyle
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
      >
        <Toolbar
          sx={{
            backgroundColor: "background.paper",
            minHeight: "100% !important",
            px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && (
            <Box sx={{ width: 200 }}>
              <Logo sx={{ mr: 2.5 }} />
            </Box>
          )}

          {!isDesktop && (
            <>
              <IconButtonAnimate
                onClick={onOpenSidebar}
                sx={{ mr: 1, color: "text.primary" }}
              >
                <Iconify icon="eva:menu-2-fill" />
              </IconButtonAnimate>
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <NotificationsPopover />
            <Badge
              color="error"
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  top: "7px",
                  right: "7px",
                },
              }}
            >
              <IconButtonAnimate
                onClick={() => handleOpenSettings()}
                className={classes.rotateIcon}
                sx={{
                  width: 35,
                  height: 35,
                  backgroundColor: "rgba(99, 115, 129, 0.08)",
                }}
              >
                <Iconify icon="solar:settings-bold-duotone" />
              </IconButtonAnimate>
            </Badge>
            <LanguagePopover />
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>

      <Settings handleClose={handleCloseSettings} open={openSettings} />
    </>
  );
}
