import DatePicker from "@mui/lab/DatePicker";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import useLocales from "src/hooks/useLocales";

import UserAutoComplete from "src/components/UsersAutoComplete";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import AmountType from "./components/amountType";
import RowsPerPage from "./components/rowsPerPage";
import UsersSearch from "src/components/autoComplete/users";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";

const defaultValues = {
  start_date: "",
  end_date: "",
  user_name: "",
  amount_type: "all",
  // rows_page: "10",
};

const FilterCard = ({ methods, onFilter }) => {
  const { translate } = useLocales();

  const { control, setValue } = methods;
  return (
    <div>
      <Box>
        <FormProvider methods={methods} onSubmit={onFilter}>
          <Box
            sx={{
              display: "grid",
              rowGap: 3,
              columnGap: 2,
              marginTop: 3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(5, 1fr)",
              },
            }}
          >
            <RHFDatePicker
              label={translate("adminFinancial.Ewallet.pickStartDate")}
              name="start_date"
              size="small"
            />
            <RHFDatePicker
              label={translate("adminFinancial.Ewallet.pickEndDate")}
              name="end_date"
              size="small"
            />

            {/* <UserAutoComplete
                freeSolo={false}
                multiple={false}
                onChange={(_, v) => {
                  setValue("user_name", v.id);
                }}
              /> */}
            <UsersSearch name="user_id" props={{ size: "small" }} />
            <AmountType />
            {/* <RowsPerPage /> */}
            <Box>
              <Button type="submit" variant="contained" name="get-report">
                {translate("userFinancial.eWallet.getReport")}
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </div>
  );
};

export default FilterCard;
