import { Container, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoginForm } from "src/sections/auth/login";
import Message from "./Message";
import NoAccountSection from "./NoAccountSection";

import { Link as RouterLink } from "react-router-dom";
import useResponsive from "src/hooks/useResponsive";
import { PATH_AUTH } from "src/routes/paths";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const MainSection = () => {
  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Message />
        <Typography variant="h3" sx={{ mb: 2 }}>
          Hi, Welcome Back
        </Typography>
        <LoginForm />
        <Typography variant="body2" sx={{ mt: { md: 2, xs: 1 } }}>
          Don’t have an account &nbsp;? &nbsp;&nbsp;
          <Link
            variant="subtitle2"
            component={RouterLink}
            to={PATH_AUTH.register}
          >
            Sign Up
          </Link>
        </Typography>
        {/* <NoAccountSection /> */}
      </ContentStyle>
    </Container>
  );
};

export default MainSection;
