import { useRoutes } from "react-router-dom";
import PDFViewer from "../components/pdfViewer";
import admin from "./admin";
import auth from "./auth";
import main from "./main";
import user from "./user";
import useAuth from "src/hooks/useAuth";
import { useEffect, useState } from "react";

const Router = () => {
  const { user: userProfile } = useAuth();
  const isVaultOn = !Boolean(userProfile?.is_vault);

  const [userArray, setUserArray] = useState(user);
  useEffect(() => {
    if (isVaultOn) {
      const arrayUser = user.map((item) => {
        return {
          ...item,
          children: item.children.filter(
            (child) => child.path !== "crowdProduct"
          ),
        };
      });
      setUserArray(arrayUser);
    } else {
      setUserArray(user);
    }
  }, [isVaultOn, admin, user]);

  return useRoutes([
    ...auth,
    ...userArray,
    ...admin,
    ...main,
    { path: "/pdf", element: <PDFViewer /> },
  ]);
};

export default Router;
