import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const CrowdProducts = Loadable(
  lazy(() => import("src/pages/admin/CrowdProduct/index"))
);
const ProductManagement = Loadable(
  lazy(() => import("src/pages/admin/CrowdProduct/product/index"))
);

const AddVault = Loadable(
  lazy(() =>
    import("src/pages/admin/CrowdProduct/product/components/CreateVault")
  )
);
const EditVault = Loadable(
  lazy(() =>
    import("src/pages/admin/CrowdProduct/product/components/EditVault")
  )
);
const PurchaseHistory = Loadable(
  lazy(() => import("src/pages/admin/CrowdProduct/vaultPurchaseHistory/index"))
);
const CreateVaultUpdate = Loadable(
  lazy(() =>
    import("src/pages/admin/CrowdProduct/product/vaultUpdates/Update/Create")
  )
);

const VaultUpdates = Loadable(
  lazy(() => import("src/pages/admin/CrowdProduct/product/vaultUpdates"))
);
const EditVaultUpdate = Loadable(
  lazy(() =>
    import("src/pages/admin/CrowdProduct/product/vaultUpdates/Update/Edit")
  )
);

const crowdProducts = [
  {
    path: "crowdProducts",
    children: [
      { index: true, element: <Navigate to="vault" /> },
      {
        path: "vault",
        children: [
          { index: true, element: <ProductManagement /> },
          { path: "add", element: <AddVault /> },
          { path: ":id", element: <EditVault /> },
        ],
      },
      {
        path: "update",
        children: [
          { path: ":id", element: <VaultUpdates /> },
          { path: "add", element: <CreateVaultUpdate /> },
          { path: "edit/:id", element: <EditVaultUpdate /> },
        ],
      },
      { path: "purchase-history", element: <PurchaseHistory /> },
    ],
  },
];

export default crowdProducts;
