import Page from "src/components/Page";
import RootStyle from "../shared/rootStyle";
import Header from "./components/Header";
import ImageCard from "./components/ImageCard";
import MainSection from "./components/MainSection";
import useQueryParams from "src/hooks/useQueryParams";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { queryObject, deleteParam } = useQueryParams();
  useEffect(() => {
    if (queryObject.message) {
      enqueueSnackbar(queryObject.message);
    }
    deleteParam("message");
  }, []);

  return (
    <Page title="Login">
      <RootStyle>
        <Header />
        <MainSection />
        <ImageCard />
      </RootStyle>
    </Page>
  );
};

export default Login;
