import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import Users from "src/components/users";
import useLocales from "src/hooks/useLocales";


const Filter = ({ onFilter ,methods}) => {
  
  const { translate } = useLocales();
  
  return (
    <FormProvider methods={methods} onSubmit={onFilter}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
            sm: "repeat(4, 1fr)",
          },
          rowGap: 3,
          columnGap: 3,
          
        }}
      >
        <RHFDatePicker
          label={translate("adminFinancial.payout.pickStartDate")}
          name="start_date"
          size="small"
        />
        <RHFDatePicker
          label={translate("adminFinancial.payout.pickEndDate")}
          name="end_date"
          size="small"
        />
        <Users
          name="user_id"
          label={translate("adminStore.businessBuilder.userName")}
          size="small"
        />
        <Button type="submit" variant="contained" size="medium">
          Get Report
        </Button>
      </Box>
    </FormProvider>
  );
};

export default Filter;
