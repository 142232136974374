import { Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "src/components/Image";
import useResponsive from "src/hooks/useResponsive";
import LoginBanner from "src/images/login.webp";
import AuthImage from "src/images/auth-image.jpg";
import animationVideo from "src/video/Evolvely- Logo_Update.mp4";
import { useEffect, useRef } from "react";

const ImageCard = () => {
  const mdUp = useResponsive("up", "md");

  return (
    mdUp && (
      <SectionStyle>
       <video
        src={animationVideo}
        autoPlay
        muted
        controls={false}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      </SectionStyle>
    )
  );
};

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "60%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(0, 0, 0, 2),
  maxHeight: "100vh",
  overflow: "hidden",
}));

export default ImageCard;
