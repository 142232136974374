import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAuth from "src/hooks/useAuth";
import * as Yup from "yup";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const defaultValues = {
  email: "",
  password: "",
  remember: true,
};

const useLogin = () => {
  const { login, getUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setError, handleSubmit, setValue } = methods;
  const [secret, setSecret] = useState(null);
  const [otp, setOTP] = useState(null);

  const onSubmit = async (data) => {
    const {
      status,
      data: res,
      message,
      secret,
      isMail,
    } = await login(data.email, data.password, data.remember);
    if (status) {
      enqueueSnackbar(`Welcome ${res}`);
    } else if (secret) {
      setSecret(secret);
      setValue("secret" ,secret)
    } else if (isMail) {
      setOTP(true);
      enqueueSnackbar(message);
    } else {
      setError("afterSubmit", {
        message,
      });
    }
  };

  return { secret, otp, onSubmit: handleSubmit(onSubmit), methods };
};

export default useLogin;
