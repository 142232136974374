import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Iconify from "../Iconify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { snakeCase } from "lodash";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import useLocales from "src/hooks/useLocales";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const convertDate = (originalDate) => {
  const formattedDate = moment(originalDate).format("DD/MM/YYYY");
  return formattedDate;
};
const FilterBar = ({
  children,
  openByDefault = false,
  action,
  methods,
  onSubmit,
}) => {
  const { translate } = useLocales();
  const [openFilter, setOpenFilter] = useState(() => {
    const isMobileView = window.innerWidth <= 600;
    if (isMobileView) {
      return false;
    }
    return openByDefault;
  });

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 600;
      setOpenFilter(isMobileView ? false : openByDefault);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [openByDefault]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dateValue, setDateValue] = useState("latest.allTime");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setDateValue(e.currentTarget.dataset.value);
    const value = snakeCase(e.currentTarget.dataset.value);
    checkFilterDate(value, methods);
    onSubmit();
    setAnchorEl(null);
  };
  const { palette } = useTheme();

  const isDark = palette.mode === "dark";

  return (
    <>
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          justifyContent: "right",
          textAlign: "right",
          mb: 1,
        }}
      >
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
        <Box sx={{ mr: 1 }}>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ backgroundColor: isDark ? "" : "white" }}
            size="medium"
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            startIcon={<Iconify icon="uil:calender" />}
          >
            {translate(dateValue)}
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleClose}
              data-value="latest.today"
              disableRipple
            >
              {translate("latest.today")}
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              data-value="latest.last7Day"
              disableRipple
            >
              {translate("latest.last7Day")}
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              data-value="latest.currentMonth"
              disableRipple
            >
              {translate("latest.currentMonth")}
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              data-value="latest.lastMonth"
              disableRipple
            >
              {translate("latest.lastMonth")}
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              data-value="latest.last12Month"
              disableRipple
            >
              {translate("latest.last12Month")}
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              data-value="latest.allTime"
              disableRipple
            >
              {translate("latest.allTime")}
            </MenuItem>
          </StyledMenu>
        </Box>
        <Button
          variant="outlined"
          color="inherit"
          sx={{ backgroundColor: isDark ? "" : "white" }}
          size="medium"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <Iconify
            sx={{
              color: isDark ? "" : "black",
            }}
            width={20}
            height={20}
            icon={openFilter ? "mingcute:close-fill" : "cil:list-filter"}
          />
        </Button>
      </Box>
      <Collapse in={openFilter}>
        <Card sx={{ p: 3, marginBottom: 2 }}>{children}</Card>
      </Collapse>
    </>
  );
};

const checkFilterDate = (value, methods) => {
  const today = moment();
  const tomorrow = moment(today).add(1, "days");
  const sevenDaysAgoDate = moment(today).subtract(7, "days");

  const lastMonthStart = moment(today).subtract(1, "month").startOf("month");
  const lastMonthEnd = moment(today).subtract(1, "month").endOf("month");
  const last12MonthsStart = moment(today)
    .subtract(12, "months")
    .startOf("month");
  const last12MonthsEnd = moment(today).subtract(1, "months").endOf("month");

  const currentMonthStart = moment(today).startOf("month");
  const currentMonthEnd = moment(today).endOf("month");
  const { setValue } = methods;
  if (value === "latest_today") {
    setValue("start_date", convertDate(today));
    setValue("end_date", convertDate(tomorrow));
  }
  if (value === "latest_current_month") {
    setValue("start_date", convertDate(currentMonthStart));
    setValue("end_date", convertDate(currentMonthEnd));
  }
  if (value === "latest_last_7_day") {
    setValue("start_date", convertDate(sevenDaysAgoDate));
    setValue("end_date", convertDate(tomorrow));
  }
  if (value === "latest_last_month") {
    setValue("start_date", convertDate(lastMonthStart));
    setValue("end_date", convertDate(lastMonthEnd));
  }
  if (value === "latest_last_12_month") {
    setValue("start_date", convertDate(last12MonthsStart));
    setValue("end_date", convertDate(last12MonthsEnd));
  }
  if (value === "latest_all_time") {
    setValue("start_date", "");
    setValue("end_date", "");
  }
};
export default FilterBar;
