import { lazy } from "react";
import Loadable from "src/routes/Loadable";
const WalletSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/wallet/index"))
);

const wallet = [
  {
    path: "wallet-settings",
    element: <WalletSettings />,
  },
];
export default wallet;
