import { Stack, Typography } from "@mui/material";
import React from "react";
import { CURRENCY } from "src/config";

// currency obj ref site : https://www.eurochange.co.uk/travel/tips/world-currency-abbreviations-symbols-and-codes-travel-money

const obj = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  CNY: "¥",
  EGP: "£",
  INR: "₹",
  JPY: "¥",
  USDT: "USDT",
};

export const Currency = ({ sx, children }) => {
  const symbol = obj[CURRENCY];
  if (children) {
    return (
      <>
        <Stack direction="row" sx={{ alignItems: "baseline" }}>
          <Typography variant="caption" sx={sx}>
            {symbol}
          </Typography>
          &nbsp; {children}
        </Stack>
      </>
    );
  } else {
    return symbol;
  }
};
