import React from "react";
import useLoginWithEmailVerify from "./hooks/use-email-login";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { FormProvider, RHFTextField } from "src/components/hook-form";

const Index = ({ otpOpen, data }) => {
  const { methods, onSubmit } = useLoginWithEmailVerify(otpOpen,data);
//   const open = Boolean(secret);

  const {
    formState: { isSubmitting },
  } = methods;

  return (
    <Dialog open={otpOpen}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Email Authentication 
          </Typography>
          <Stack spacing={3}>
            <RHFTextField label="Enter OTP" type="number" name="code" />
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              verify
            </LoadingButton>
          </Stack>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
};

export default Index;
