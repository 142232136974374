// @mui
import { alpha, styled } from '@mui/material/styles';
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
// config
import { ICON, NAVBAR } from '../../../config';

// ----------------------------------------------------------------------

export const ListItemStyle = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})(({ activeRoot, activeSub, subItem, theme }) => ({
    ...theme.typography.body2,
    position: 'relative',
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    color: "#fff",

    // activeRoot
    ...(activeRoot && {
        ...theme.typography.subtitle2,
        color: "#fff",
        backgroundColor: "#0f172a",
        "& span": { color: "#6366f1" },
    }),

    // subItem
    ...(subItem && {
        height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
        color: "#8e99b1",
    }),


    // activeSub
    ...(activeSub && {
        ...theme.typography.subtitle2,
        color: "#6366f1",
    }),

}));



export const ListItemTextStyle = styled(ListItemText, {
    shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['width', 'opacity'], {
        duration: theme.transitions.duration.shorter,
    }),
    ...(isCollapse && {
        width: 0,
        opacity: 0,
    }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
    width: ICON.NAVBAR_ITEM,
    height: ICON.NAVBAR_ITEM,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': { width: '100%', height: '100%' },


});